// fetchに使う細かなコードを入れる
// （複数参照が入る関係上、できるだけ1ファイルにまとめる）

// キャッシュ入れ替え時には、「オブジェクトごと」変更する
let cache = {
  key: '',
  value: ''
};

// キャッシュは後々考える
const getCsrfToken = () => {
  const key = (document.head.querySelector('meta[name="csrf-param"]') as HTMLMetaElement).content;
  const value = (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content;
  if(key !== cache.key || value !== cache.value) {
    cache = {key, value};
  }
  return cache;
};

const getCsrfHeader = () => ({'X-CSRF-Token': getCsrfToken().value, 'X-Requested-With': 'XMLHttpRequest'});

const okOrReject = (res: Response) => res.ok || Promise.reject();
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const jsonOrReject = <T = any,>(res: Response) => res.ok ? res.json() as Promise<T> : Promise.reject();

export {
  getCsrfHeader,
  getCsrfToken,
  jsonOrReject,
  okOrReject
};

